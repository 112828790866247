import React from 'react';

import Popup from './popup';


function Contact() {

    const [isPopupOpen, setIsPopupOpen] = React.useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    
    return (


<div className="main-content">
    <div className="text">

        <Popup isPopupOpen={isPopupOpen} togglePopup={togglePopup} />

        <div>
                <h1>Contactez-nous</h1> 

        </div>

        <div>
            <p>
                Vous êtes intéressé par une solution qui, grâce à une analyse
                approfondie de vos données opérationnelles, permettrait d'optimiser 
                vos performances, réduire vos coûts, et augmenter vos gains pour chaque 
                heure d'exploitation ?

                N'hésitez pas à nous contacter par email, par téléphone, ou en
                prenant rendez-vous directement en ligne.
            </p>
        </div>

        <div class="button-container-contact">
                    <button class="custom-button" onClick={togglePopup}>📅 Prenez rendez-vous et discutons de vos besoins </button>
        </div>


        <section class="coordonnées">
            <h2>Coordonnées :</h2>
            
            <div className="coordonnees">
                <ul>
                <li>
                    <strong>E-mail :</strong>{' '}
                    <a href="mailto:contact@datadriven-dynamix.fr">
                    contact@datadriven-dynamix.fr
                    </a>
                </li>
            
                <li>
                    <strong>Téléphone mobile :</strong> (+33) 06.83.32.25.93
                </li>
            
                <li>
                    <strong>Téléphone fixe :</strong> (+33) 09.81.45.37.57
                </li>
            
                <li>
                    <a href="https://www.linkedin.com/company/datadriven-dynamix/" target="_blank" rel="noopener noreferrer">
                        <img src="img/linkedin_logo_icon.png" alt="LinkedIn" />
                    </a>
                </li>
                </ul>
            </div>
        </section>
    </div>
</div>


);
}


export default Contact;