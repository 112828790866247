import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles.css';
import './styles-header.css'; 
import './styles-footer.css';
import './styles-popup-calendrier.css'; 
import './styles-accueil.css';
import './styles-contact.css';


// Créer une racine pour le rendu avec React 18
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// Rendre le composant App dans la racine
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);