import React from 'react';


function Banniere() {

    return (
        <div className="header">

            <img src="img/logo_marque_datadriven_dynamix_clair.svg" alt="Logo DataDriven Dynamix" />

        </div>
    );
}


export default Banniere;