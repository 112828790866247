import React from 'react';


function Confidentialite({showCookies}) {

    return (

        <div className="main-content">

            <div className="text">

                <h1>Politique de Confidentialité</h1>

                <h2>1. Introduction</h2>
                <p>DataDriven Dynamix, entreprise individuelle gérée par Dimitri Lefebvre, 
                    s'engage à protéger la confidentialité et la sécurité de vos données 
                    personnelles. La présente Politique de Confidentialité vous informe 
                    sur la manière dont nous collectons, utilisons, et protégeons vos données 
                    personnelles lors de votre visite sur notre site web.</p>

                <h2>2. Responsable du traitement des données</h2>
                <p>Le responsable du traitement des données personnelles collectées sur 
                    le site www.datadrivendynamix.com est Dimitri Lefebvre, fondateur et 
                    gérant de DataDriven Dynamix.</p>

                <h2>3. Données collectées</h2>
                <p>Nous pouvons collecter les données personnelles suivantes lorsque vous 
                    utilisez notre site ou interagissez avec nous :</p>
                <ul>
                    <li>Nom et prénom</li>
                    <li>Adresse email</li>
                    <li>Numéro de téléphone</li>
                    <li>Votre société</li>
                    <li>Informations de prise de rendez-vous (date, heure, sujet)</li>
                    <li>Toute autre information que vous choisissez de nous communiquer 
                        via les formulaires de contact</li>
                </ul>

                <h2>4. Finalité du traitement</h2>
                <p>Les données personnelles que nous collectons sont utilisées pour les 
                    finalités suivantes :</p>
                <ul>
                    <li>Gestion des prises de rendez-vous et des relations avec les clients</li>
                    <li>Réponse à vos demandes de renseignements</li>
                    <li>Communication avec nos clients et prospects, y compris par 
                        l'envoi d'emails d'information ou promotionnels</li>
                    <li>Amélioration de nos services et de notre site web</li>
                </ul>

                <h2>5. Destinataires des données</h2>
                <p>Les données personnelles collectées sont exclusivement destinées 
                    à Dimitri Lefebvre, le seul gestionnaire de la société DataDriven 
                    Dynamix. Aucun salarié ou tiers n'a accès à ces données sans votre 
                    consentement préalable.</p>

                <h2>6. Durée de conservation des données</h2>
                <p>Vos données personnelles sont conservées pendant une durée n'excédant 
                    pas celle nécessaire aux finalités pour lesquelles elles sont collectées :</p>
                <ul>
                    <li>Données de contact et d'abonnement : conservées pendant toute 
                        la durée de la relation commerciale ou jusqu'à ce que vous demandiez leur suppression.</li>
                    <li>Données de prise de rendez-vous : conservées pendant une durée de 
                        3 ans après la fin de la relation commerciale.</li>
                </ul>

                <h2>7. Sécurité des données</h2>
                <p>Nous mettons en œuvre des mesures techniques et organisationnelles appropriées 
                    pour garantir la sécurité de vos données personnelles et empêcher leur 
                    divulgation, perte, ou destruction accidentelle ou illicite.</p>

                <h2>8. Vos droits</h2>
                <p>Conformément à la réglementation en vigueur, vous disposez des droits suivants 
                    concernant vos données personnelles :</p>
                <ul>
                    <li><strong>Droit d'accès</strong> : Vous pouvez demander à accéder aux données 
                    personnelles que nous détenons à votre sujet.</li>
                    <li><strong>Droit de rectification</strong> : Vous pouvez demander la correction 
                    de vos données personnelles si elles sont inexactes ou incomplètes.</li>
                    <li><strong>Droit d'effacement</strong> : Vous pouvez demander la suppression de 
                    vos données personnelles.</li>
                    <li><strong>Droit d'opposition</strong> : Vous pouvez vous opposer au traitement 
                    de vos données personnelles pour des raisons légitimes.</li>
                    <li><strong>Droit à la portabilité</strong> : Vous pouvez demander que vos données 
                    personnelles vous soient fournies dans un format structuré, couramment utilisé et 
                    lisible par machine.</li>
                </ul>
                <p>Pour exercer vos droits, vous pouvez nous contacter à l'adresse suivante : 
                    <a href="mailto:contact@datadriven-dynamix.fr">contact@datadriven-dynamix.fr</a>.</p>

                <h2>9. Cookies</h2>
                <p>Notre site utilise des cookies pour améliorer l'expérience utilisateur et 
                    analyser l'utilisation du site. Pour plus d'informations sur l'utilisation des 
                    cookies, consultez notre <a href="#cookies" onClick={showCookies}>Politique de Cookies</a>.</p>

                <h2>10. Modifications de la politique de confidentialité</h2>
                <p>Nous nous réservons le droit de modifier cette Politique de Confidentialité à 
                    tout moment. Les modifications seront publiées sur cette page. Nous vous 
                    encourageons à consulter régulièrement cette page pour rester informé des éventuelles mises à jour.</p>

                <h2>11. Contact</h2>
                <p>Pour toute question concernant cette Politique de Confidentialité ou pour 
                    exercer vos droits, vous pouvez nous contacter à l'adresse suivante : 
                    <a href="mailto:contact@datadriven-dynamix.fr">contact@datadriven-dynamix.fr</a>.</p>

            </div>
        </div>

);
}

export default Confidentialite;