import React from 'react';


function Mentions({showCookies}) {

    return (


        <div className="main-content">
            <div className="text">
            <h1>Mentions Légales</h1>

            <h2>1. Présentation de la société</h2>
            <p><strong>Nom commercial</strong> : DataDriven Dynamix</p>
            <p><strong>Forme juridique</strong> : Entreprise Individuelle (EI)</p>
            <p><strong>Siège social</strong> : [En cours de création]</p>
            <p><strong>Immatriculation</strong> : En cours de création</p>
            <p><strong>Responsable de la publication</strong> : Dimitri Lefebvre</p>
            <p><strong>Contact</strong> : <a href="mailto:contact@datadriven-dynamix.fr">contact@datadriven-dynamix</a></p>
            <p><strong>Téléphone</strong> : (+33) 09.81.45.37.57</p>
            <p><strong>Hébergeur du site</strong> : OVH, 2 rue Kellermann - 59100 Roubaix - France, www.ovhcloud.com</p>

            <h2>2. Propriété intellectuelle</h2>
            <p>L'ensemble des contenus (textes, images, vidéos, graphismes, logos, icônes, sons, logiciels, etc.) présents 
                sur ce site sont la propriété exclusive de DataDriven Dynamix ou de ses partenaires. Toute reproduction, distribution, 
                modification, adaptation, retransmission ou publication de ces éléments est strictement interdite sans l'accord 
                écrit préalable de DataDriven Dynamix. Toute exploitation non autorisée du site ou de l'un des éléments qu'il 
                contient sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des 
                articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>

            <h2>3. Collecte de données personnelles</h2>
            <p>DataDriven Dynamix peut être amenée à collecter des données personnelles vous concernant lors de votre visite 
                sur le site. Ces données sont collectées et traitées dans le respect des dispositions du Règlement Général 
                sur la Protection des Données (RGPD) et de la loi Informatique et Libertés.</p>
            <p><strong>Responsable du traitement</strong> : Dimitri Lefebvre</p>
            <p><strong>Finalité du traitement</strong> : Les données collectées sont utilisées pour la gestion d'un fichier 
                client permettant d'enregistrer les prises de rendez-vous, ainsi que pour la collecte d'adresses email afin de 
                communiquer avec les clients et prospects.</p>
            <p><strong>Destinataires des données</strong> : Les données personnelles collectées sont exclusivement destinées 
                à Dimitri Lefebvre, le seul gestionnaire de la société DataDriven Dynamix.</p>
            <p><strong>Droits des utilisateurs</strong> : Conformément à la réglementation en vigueur, vous disposez d'un 
                droit d'accès, de rectification, d'effacement, d'opposition, et de portabilité des données vous concernant. Vous 
                        pouvez exercer ces droits en nous contactant à l'adresse suivante : <a href="mailto:contact@datadriven-dynamix.fr">contact@datadriven-dynamix.fr</a>.</p>

            <h2>4. Limitation de responsabilité</h2>
            <p>DataDriven Dynamix s'efforce d'assurer au mieux de ses possibilités, l'exactitude et la mise à jour des informations
                diffusées sur ce site. Cependant, DataDriven Dynamix ne peut garantir l'exactitude, la précision ou l'exhaustivité 
                des informations mises à disposition sur ce site. En conséquence, DataDriven Dynamix décline toute responsabilité 
                pour toute imprécision, inexactitude ou omission portant sur des informations disponibles sur ce site.</p>

            <h2>5. Liens hypertextes</h2>
            <p>Le site www.datadrivendynamix.com peut contenir des liens hypertextes vers d'autres sites internet. DataDriven 
                Dynamix n'a pas de contrôle sur ces sites et ne peut être tenue responsable de leur contenu ou de leurs pratiques 
                en matière de protection des données personnelles.</p>

            <h2>6. Cookies</h2>
            <p>Le site www.datadrivendynamix.com utilise des cookies pour améliorer l'expérience utilisateur, réaliser des 
                statistiques d'audience, et proposer des contenus adaptés à vos centres d'intérêt. Pour en savoir plus sur la 
                gestion des cookies, vous pouvez consulter notre <a href="#cookies" onClick={showCookies}>Politique de Cookies</a>.</p>

            <h2>7. Droit applicable et juridiction compétente</h2>
            <p>Les présentes mentions légales sont régies par le droit français. En cas de litige, et à défaut d'accord amiable, 
                les tribunaux français seront seuls compétents.</p>
            </div>
        </div>

        );
    }

export default Mentions;