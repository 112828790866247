import React from 'react';

import { createRoot } from 'react-dom/client';

import Banniere from './components/banniere';
import Menu from './components/menu';
import BurgerMenu from './components/burger_menu';
import Footer from './components/footer';
import Accueil from './components/accueil';
import Contact from './components/contact';
import Cookies from './components/politique_cookies';
import Mentions from './components/mentions_legales';
import Confidentialite from './components/politique_confidentialite';


function App() {
    const [visibleComponent, setVisibleComponent] = React.useState("accueil");

    const showAccueil = () => {
        setVisibleComponent("accueil");
        window.scrollTo(0, 0);
    };

    const showContact = () => {
        setVisibleComponent("contact");
        window.scrollTo(0, 0);
    };

    const showLegales = () => {
        setVisibleComponent("mentions");
        window.scrollTo(0, 0);
    };

    const showCookies = () => {
        setVisibleComponent("cookies");
        window.scrollTo(0, 0);
    };

    const showConfidentialite = () => {
        setVisibleComponent("confidentialite");
        window.scrollTo(0, 0);
    };

    return (
        <div className="root">
            <Banniere  />
            <BurgerMenu showAccueil={showAccueil} showContact={showContact}  />
            <Menu showAccueil={showAccueil} showContact={showContact} />

            {visibleComponent === "accueil" && <Accueil showContact={showContact}/>}
            {visibleComponent === "contact" && <Contact />}
            {visibleComponent === "mentions" && <Mentions showCookies={showCookies} />}
            {visibleComponent === "cookies" && <Cookies showConfidentialite={showConfidentialite} />}
            {visibleComponent === "confidentialite" && <Confidentialite showCookies={showCookies}/>}

            <Footer showCookies={showCookies} showLegales={showLegales}  />
        </div>
    );
}

// Utilisation de createRoot à la place de ReactDOM.render()
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<App />)

export default App;