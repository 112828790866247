import React from 'react';


function Footer({ showCookies, showLegales }) {
    return (
        <div className="footer">
            <div className="footer_frame">
                <div className="column">
                    &copy; 2024 DataDriven Dynamix. Tous droits réservés.
                </div>

                <div className="column">
                    <a href="https://www.linkedin.com/company/datadriven-dynamix/" target="_blank" rel="noopener noreferrer">
                        <img src="img/linkedin_logo_icon.png" alt="LinkedIn" />
                    </a>
                </div>

                <div className="column">
                    <a href="#mentions" onClick={showLegales} className="active">Mentions légales</a>
                </div>

                <div className="column">
                    
                    <a href="#cookies" onClick={showCookies} className="active">Politique cookie</a>
                </div>
            </div>
        </div>

    );
}


export default Footer;