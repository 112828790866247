import React from 'react';


function Popup({ isPopupOpen, togglePopup }) {
    return (
        <>
            {isPopupOpen && (
                <div id="popup" className="popup" onClick={togglePopup}>
                    <div className="popup-content" onClick={(event) => event.stopPropagation()}>
                        <span id="closePopupBtn" className="close" onClick={togglePopup}>&times;</span>
                        <iframe 
                            src="https://calendly.com/datadriven-dynamix" 
                            width="100%" 
                            height="100%" 
                            frameborder="0"
                            title="Calendrier de prise de rendez-vous">
                        </iframe>
                    </div>
                </div>
            )}
        </>
    );
}


export default Popup;