import React from 'react';


function BurgerMenu({ showAccueil , showContact}) {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const [activeLink, setActiveLink] = React.useState('');

    const handleClick = (linkName, callback) => {
        setActiveLink(linkName);
        callback();
    };

    return (
        <div className="burger-menu-container">
            <div className="menu-logo-container">
                <div class="img-burger-menu">
                    <img src="img/logo_marque_datadriven_dynamix_clair.svg" alt="Logo datadriven dynamix" />
                </div>
            
                {!isOpen &&(
                <div className="menu-container" onClick={toggleMenu}>
                    <div className="burger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="burger-icon-text">Menu</div>
                </div>
                )}

                {isOpen &&(
                <div className="menu-container" onClick={toggleMenu}>
                    <div class="close-cross">✖</div>
                    <div className="burger-icon-text">Fermer</div>
                </div>
                    )
                }
            </div>

            {isOpen && (
                <div className="burger-menu">
                
                    
                    <a href="#accueil" onClick={() => handleClick('accueil', showAccueil)}  className={activeLink === 'accueil' ? 'active' : ''}>Accueil</a>

                    <a href="#contact" onClick={() => handleClick('contact', showContact)}  className={activeLink === 'contact' ? 'active' : ''} >Contact</a>

                </div>
            )}
        </div>
    );
}

export default BurgerMenu;