import React from 'react';


function Menu({ showAccueil, showContact}) {

    const [activeLink, setActiveLink] = React.useState('accueil');

    const handleClick = (linkName, callback) => {
        setActiveLink(linkName);
        callback();
    };

    return (
        <div className="header_menu">

            <a href="#accueil" onClick={() => handleClick('accueil', showAccueil)}  className={activeLink === 'accueil' ? 'active' : ''}>Accueil</a>
            
            <a href="#contact" onClick={() => handleClick('contact', showContact)}  className={activeLink === 'contact' ? 'active' : ''} >Contact</a>

        </div>
    );
}


export default Menu;