import React from 'react';


function Cookies( {showConfidentialite} ) {

    return (

<div className="main-content">
<div className="text">
    <h1>Politique de Cookies</h1>

    <h2>1. Qu'est-ce qu'un cookie ?</h2>
    <p>Un cookie est un petit fichier texte déposé sur votre appareil (ordinateur, tablette, 
        smartphone, etc.) lors de la visite d'un site web. Les cookies permettent de stocker 
        des informations relatives à votre navigation pour faciliter et améliorer votre expérience utilisateur.</p>

    <h2>2. Types de cookies utilisés</h2>
    <p>Le site www.datadrivendynamix.com utilise les types de cookies suivants :</p>

    <h3>a. Cookies strictement nécessaires</h3>
    <p>Ces cookies sont indispensables au bon fonctionnement du site et vous permettent de 
        naviguer et d'utiliser ses fonctionnalités. Ils ne peuvent pas être désactivés dans nos systèmes.</p>

    <h3>b. Cookies de performance</h3>
    <p>Ces cookies recueillent des informations anonymes sur la manière dont les visiteurs 
        utilisent notre site. Ils nous permettent de comprendre l'utilisation du site et d'en 
        améliorer les fonctionnalités.</p>

    <h3>c. Cookies de fonctionnalité</h3>
    <p>Ces cookies permettent au site de se souvenir des choix que vous avez faits (par 
        exemple, votre nom d'utilisateur, votre langue ou la région où vous vous trouvez) et de 
        fournir des fonctionnalités améliorées et plus personnalisées.</p>

    <h3>d. Cookies de ciblage ou publicitaires</h3>
    <p>Ces cookies sont utilisés pour présenter des publicités pertinentes pour vous et 
        adaptées à vos centres d'intérêt. Ils permettent également de limiter le nombre 
        de fois où vous voyez une publicité et d'aider à mesurer l'efficacité des campagnes publicitaires.</p>

    <h2>3. Gestion des cookies</h2>
    <p>Lors de votre première visite sur notre site, un bandeau vous informe de la 
        présence de cookies et vous invite à les accepter ou à les refuser. Vous pouvez  tout moment 
        modifier vos préférences en matière de cookies via les paramètres de votre navigateur.</p>
    <p>Voici comment gérer les cookies sur différents navigateurs :</p>
    <ul>
        <li><strong>Google Chrome</strong> : <a href="https://support.google.com/chrome/answer/95647?hl=fr" target="_blank">Lien vers les instructions</a></li>
        <li><strong>Mozilla Firefox</strong> : <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies" target="_blank">Lien vers les instructions</a></li>
        <li><strong>Microsoft Edge</strong> : <a href="https://support.microsoft.com/fr-fr/help/4027947/microsoft-edge-delete-cookies" target="_blank">Lien vers les instructions</a></li>
        <li><strong>Safari</strong> : <a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac" target="_blank">Lien vers les instructions</a></li>
    </ul>
    <p>Veuillez noter que si vous désactivez certains cookies, cela peut affecter votre 
        expérience de navigation et limiter certaines fonctionnalités du site.</p>

    <h2>4. Durée de conservation des cookies</h2>
    <p>Les cookies ont une durée de vie limitée qui varie en fonction de leur type. Les 
        cookies de session expirent lorsque vous fermez votre navigateur. Les cookies persistants 
        ont une durée de vie plus longue, mais cette durée ne dépasse généralement pas 13 mois.</p>

    <h2>5. Protection des données personnelles</h2>
    <p>Les informations collectées via les cookies peuvent contenir des données personnelles. 
        Pour en savoir plus sur la manière dont nous traitons vos données personnelles, consultez 
        notre <a href="#confidentialite" onClick={showConfidentialite}>Politique de Confidentialité</a>.</p>

    <h2>6. Mise à jour de la politique de cookies</h2>
    <p>Nous nous réservons le droit de modifier la présente Politique de Cookies à tout 
        moment. Les modifications prendront effet immédiatement après leur publication sur le site. 
        Nous vous invitons à consulter régulièrement cette page pour rester informé de tout changement.</p>

    <h2>7. Contact</h2>
    <p>Pour toute question ou demande d'information complémentaire concernant notre politique 
        de cookies, vous pouvez nous contacter à l'adresse 
        suivante : <a href="mailto:contact@datadriven-dynamix.fr">contact@datadriven-dynamix.fr</a>.</p>
</div>
</div>

);
}


export default Cookies;