import React from 'react';
// import popup
import Popup from './popup';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



function Accueil({ showContact }) {

    const [isPopupOpen, setIsPopupOpen] = React.useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    
    return (

        <div className="main-content">

            <section class="titre">

                <div class="titre">

                    <h1>
                        Ensemble, transformons vos données en leviers de performance !
                    </h1>

                </div>

            </section>

            <section class="objectif">

                        <div class="objectif">

                            <div class="img-objectif">
                                <img src="img/target.svg" alt="Objectif" />
                            </div>

                            <div class="text-objectif">
                                <h2>Mon objectif ?</h2>
                                <p>
                                    Aider les entreprises à transformer leurs données brutes 
                                    en leviers d’optimisation concrets. Avec une expertise en gestion de projets industriels 
                                    et une solide formation en data science, je propose des solutions sur mesure pour 
                                    maximiser la performance de vos processus.
                                </p>
                            </div>

                        </div>
                    

            </section>

            <section class="projet-intro">

                <div class="projet-intro">
                    <h2>La data dans l'industrie : des projets concrets pour booster les performances</h2>
                
                    <div class="projet-intro-text">
                        <p>
                            Grâce à mon expérience dans le secteur industriel et à mon expertise en data 
                            science, j’ai pu mener à bien des projets qui ont un impact réel sur la 
                            performance et la réduction des coûts.
                            <br/><br/>
                            Mon approche est toujours orientée vers des 
                            solutions concrètes et directement applicables à l’industrie.

                        </p>

                    </div>

                </div>
            </section>

            <section class="section-projet">
                <div class="section-projet-content">
                    <div className="swiper-container">
                    <Swiper
                        modules={[Navigation]}
                        navigation={true}
                        slidesPerView='auto'
                        loop={true}
                        centeredSlides={true}  // Centrer la slide active
                        initialSlide={0}
                    >
                            <SwiperSlide>
                                <div class="slider-optimisation">
                                    <div class="image-slider-optimisation">
                                        <img src="img/filling_dashboard.jpg" alt="Suivi et optimisation" class="image-slider-optimisation" />
                                    </div>

                                    <div class="text-slider-optimisation">
                                        <h2>Suivi et optimisation d’un atelier de conditionnement</h2> 
                                        <p>
                                            <div class="fond">
                                                Développement 
                                                d’un tableau de bord sur-mesure pour suivre en temps réel la 
                                                production, les arrêts machine et la performance globale de l’atelier. 
                                                Ce projet a permis de réduire les temps d’arrêt de 15 % et d’augmenter 
                                                la productivité de l’équipe avec une augmenattion du TRS de 10 %.
                                                <br/><br/>
                                                <a href="/videos/Filling_Dashboard.mp4" target="_blank" rel="noopener noreferrer">
                                                    Cliquez ici pour visionner la vidéo de présentation
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div class="slider-optimisation">
                                    <div class="image-slider-optimisation">
                                        <img src="img/Dashboard_ecran.jpg" alt="Suivi et optimisation" class="image-slider-optimisation" />
                                    </div>

                                    <div class="text-slider-analyse">
                                        <h2>Analyse des performances de procédés de fabrication</h2> 
                                        <p>
                                            <div class="fond">
                                                J’ai mis en place des analyses statistiques des paramètres de process pour optimiser les 
                                                opérations de dispersion pigmentaire, améliorant ainsi la qualité des 
                                                produits. Le travail sur la stabilité des process a été essentielle 
                                                pour améliorer les processus et réduire les temps d'immobilisation des équipemensts.
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                
                            </SwiperSlide>

                            <SwiperSlide>
                                <div class="slider-optimisation">
                                    <div class="image-slider-optimisation">
                                        <img src="img/analyse_futur.jpg" alt="Suivi et optimisation" class="image-slider-optimisation" />
                                    </div>

                                    <div class="text-slider-predictive">
                                        <h2>Maintenance prédictive des équipements</h2> 
                                        <p>
                                            <div class="fond">
                                                Intégration de l’analyse de données 
                                                pour anticiper les pannes des équipements critiques. En analysant les 
                                                historiques de données machine et en mettant en place un système de maintenance 
                                                prédictive, nous avons pu réduire les pannes, augmentant ainsi 
                                                la disponibilité des machines.
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                
                            </SwiperSlide>

                    </Swiper>

                    </div>
                </div>

            </section>


            <section class="quisuisje">

                <div class="text-quisuisje">

                    <h2>Qui suis-je ?</h2>
                    <p>
                        Je suis Dimitri et après 17 ans passés dans l’industrie, j’ai décidé 
                        de fusionner mes compétences techniques et organisationnelles avec ma passion grandissante pour l’analyse 
                        de données. 
                    </p>
                    
                    <div class="funfact">

                        <div class="img-funfact">
                            <img src="img/fun_fact.svg" alt="Fun fact" />
                        </div>

                        <div class="text-funfact">      
                                <p>
                                    Je suis aussi ceinture noire et enseignant de judo et jujitsu – toujours à l'affût d'une nouvelle 
                                    façon de maîtriser <i><b>l’art de l’efficacité</b></i>, que ce soit sur les tatamis ou dans les 
                                    ateliers de production !
                                </p> 
                        </div>

                    </div>
                        
                    <div class="button-container">
                        <button class="custom-button" onClick={togglePopup}>📅 Discutons de vos besoins - RDV</button>
                        <Popup isPopupOpen={isPopupOpen} togglePopup={togglePopup} />
                    </div>
                    
                </div>



                <div class="portrait-funfact-container-mobile">

                    <div class="text-quisuisje-mobile">

                        <h2>Qui suis-je ?</h2>
                        <p>
                            Je suis Dimitri, et après 17 ans passés dans l’industrie, j’ai décidé 
                            de fusionner mes compétences techniques et organisationnelles avec ma passion grandissante pour l’analyse 
                            de données. 
                        </p>
                    </div>

                    <div class="portrait-funfact">
                            
                        <div class="portrait-mobile">
                            <img src="img/portrait.png" alt="Portrait de Dimitri Lefebvre" />
                        </div>
                        
                        <div class="funfact-mobile">

                                    <p>
                                        <img src="img/fun_fact.svg" alt="Fun fact" />Je suis aussi ceinture noire et enseignant de judo et jujitsu – toujours à l'affût d'une nouvelle 
                                        façon de maîtriser <i><b>l’art de l’efficacité</b></i>, que ce soit sur les tatamis ou dans les 
                                        ateliers de production !
                                    </p> 


                        </div>
                    </div>



                    <div class="button-container-mobile">
                        <button class="custom-button" onClick={togglePopup}>📅 Discutons de vos besoins - RDV</button>
                        <Popup isPopupOpen={isPopupOpen} togglePopup={togglePopup} />
                    </div>

                </div>




                <div class="img-quisuisje">

                    <div class="image-fondu-bords">
                        <img src="img/portrait.png" alt="Portrait de Dimitri Lefebvre" />
                    </div>

                </div>

            </section>

            <section class="contact">
                <div class="text-contact">
                    <h2>Vous avez un projet en tête ?</h2>
                    <p>
                        N'hésitez pas à <a href="#contact" onClick={ showContact }>me contacter</a> pour discuter de vos besoins en matière d'optimisation 
                        industrielle et de data science. 
                        <br/><br/>
                        Je suis à votre disposition pour trouver la solution 
                        qui propulsera votre performance que vous ayez besoin de : 
                        <ul>
                            <li>Développer des tableaux de bord opérationnels</li>
                            <li>Optimiser vos procédés de fabrication</li>
                            <li>Mettre en place des solutions de maintenance prédictive</li>
                            <li>Analyse des Retours d'Expérience automatisée</li>
                        </ul>
                    </p>
                </div>
            </section>

        </div>

);
}


export default Accueil;